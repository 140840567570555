/* eslint-disable react/jsx-key */
import { UIText1, Button } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './tourSlider.css';

export interface ITourComponentProps {
  tourBannerImageUrl: string;
  isExternal?: boolean;
  onClick: () => void;
}

export const TourSlide = ({ onClick, tourBannerImageUrl, isExternal }: ITourComponentProps) => {
  return (
    <div className={styles['tour']} id="tourSlide">
      <div className={styles['content']}>
        <div
          className={styles['layout']}
          style={{ backgroundImage: `url(${tourBannerImageUrl})` }}
          data-tour="layout"
          data-testid="tour"
          onClick={onClick}
        >
          <div className={styles['text']}>
            <UIText1 userSelect="none" color="text-inverted-default">
              {isExternal ? 'Погуляйте по комнатам,' : 'Погуляйте по комнатам, чтобы оценить планировку.'}
            </UIText1>
            <UIText1 userSelect="none" color="text-inverted-default">
              {isExternal ? 'чтобы оценить планировку и ремонт.' : 'Мебель использована для примера.'}
            </UIText1>
          </div>
          <Button onClick={onClick} theme="fill_white_primary" size="XS">
            Погулять по квартире
          </Button>
        </div>
      </div>
    </div>
  );
};

TourSlide.displayName = 'TourSlide';
